@import "../../../assets/scss/variables";

.testimonial {
    margin-top: 40px;
    .thumb {
        margin-bottom: 24px;
    }
    .clientInfo {
        h4 {
            font-size: 24px;

            position: relative;

            margin-bottom: 19px;
            padding-bottom: 24px;

            color: #010F54;
            &::before {
                position: absolute;
                bottom: 0;
                left: 50%;

                width: 150px;
                height: 3px;

                content: "";
                transform: translateX(-50%);

                background: linear-gradient(to left, #FCCEC8, #E05941);
            }
        }
        p {
            font-size: 16px;
            line-height: 27px;

            margin: 0;
        }
    }
}

@media #{$md-layout} {
    .testimonial .clientInfo h4 {
        font-size: 20px;

        margin-bottom: 19px;
        padding-bottom: 25px;
    }
}
