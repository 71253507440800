@import "../../../assets/scss/variables";

.headerArea {
    position: absolute;
    z-index: 99;
    top: 50px;
    right: 0;
    left: 0;

    width: auto;

    transition: 0.3s;

    &.stick {
        position: fixed;
        top: 0;

        padding: 15px 0;

        animation: 700ms ease-in-out;
        animation-name: fadeInDown;

        background-color: #00082E;
    }
}

/* offcanvas mobile menu */

.headerMobileNavigation {
    padding: 20px 0;

    .mobileNavigation {
        .headerCartIcon {
            a {
                span {
                    left: 50%;
                }
            }
        }
    }
}

.mobileButtonWrapper {
    button {
        font-size: 40px;

        padding: 0;

        color: #FFF;
        border: none;
        background: none;

        @media #{$xxs-layout} {
            font-size: 30px;

        }
    }
}

.offcanvasWidgetArea {
    margin-top: auto;
    margin-bottom: 35px;

    @media #{$sm-layout} {
        margin-bottom: 30px;
    }
}

.offCanvasContactWidget {
    margin-bottom: 20px;

    .headerContactInfo {
        flex-basis: 33.33%;
        &List {
            padding-left: 0;
            li {
                display: inline-block;

                margin-right: 25px;
                margin-bottom: 10px;
                svg {
                    font-size: 16px;

                    margin-right: 5px;
                }

                a {
                    &:hover {
                        color: $theme-color;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.offcanvasMobileMenu {
    position: fixed;
    z-index: 999999999999;
    top: 0;
    right: 0;

    width: 400px;
    max-width: 100%;
    height: 100vh;
    padding-left: 60px;

    transition: 0.6s;
    transform: translateX(100%);

    &.active {
        transform: translateX(0);
    }

    &.inactive {
        transform: translateX(calc(100% + 60px));
    }
}

.offcanvasMenuClose {
    font-size: 30px;
    line-height: 1;

    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;

    width: 60px;
    height: 55px;
    padding: 0;

    text-align: center;

    color: #FFF;
    border: none;
    background: #343538;

    &:hover,
    &:focus {
        color: #FFF;
    }

    i {
        font-size: 40px;
        line-height: 1;

        margin-top: 12px;

        transition: 0.3s;
        transform: rotate(0);
    }

    &:hover {
        i {
            transform: rotate(-90deg);
        }
    }
}

.offcanvasWrapper {
    overflow: auto;

    height: 100%;

    background-color: #FFF;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
}

.offcanvasInnerContent {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    height: 100%;
    padding: 90px 35px 0;

    @media #{$sm-layout} {
        padding: 70px 25px 0;
    }
}

.offCanvasWidgetSocial {
    a {
        font-size: 17px;

        margin: 0 10px;
        &:first-child {
            margin-left: 0;
        }

        @media #{$sm-layout} {
            margin: 0 10px;
        }

        &:hover {
            color: $theme-color;
        }
    }
}

/* offcanvas settings */

.offcanvasSettings {
    .offcanvasNavigation {
        & > ul {
            & > li {
                & > a {
                    font-size: 12px;
                    font-weight: 400;

                    padding: 5px 0;
                }

                &.menuItemHaschildren {
                    .menuExpand {
                        top: -15px;

                        height: 30px;
                        margin-top: 0;
                    }
                }
            }
        }

        ul {
            &.subMenu {
                & > li {
                    & > a {
                        padding: 5px 0;
                    }
                }
            }
        }
    }
}

.transparentBar {
    position: absolute;
    z-index: 999;
    right: 0;
    left: 0;

    width: 100%;

    @media #{$md-layout,
    $xs-layout,
    $sm-layout} {
        position: relative;
    }

    .headerTop {
        display: none !important;
    }
}

.offcanvasNavigation {
    margin-bottom: 50px;
    & > ul {
        padding-left: 0;
        li {
            &.menuItemHasChildren {
                .subMenu {
                    visibility: hidden;

                    height: 0;

                    transition: 0.3s;

                    opacity: 0;
                }

                &.active {
                    & > .subMenu {
                        visibility: visible;

                        height: 100%;

                        opacity: 1;
                    }
                }
            }
            & > a {
                font-weight: 600;

                display: block;

                padding: 10px 0;

                color: #111;
                &:hover {
                    color: $theme-color;
                }

                @media #{$sm-layout} {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    ul {
        &.subMenu {
            margin-left: 25px;
            padding-left: 0;

            transition: 0.3s;
            & > li {
                & > a {
                    font-size: 13px;
                    font-weight: 400;

                    padding: 10px 0;
                    &:hover {
                        color: $theme-color;
                    }

                    @media #{$sm-layout} {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
        }
        li {
            a {
                text-transform: uppercase;
            }

            &.menuItemHasChildren {
                position: relative;

                display: block;
                a {
                    display: block;
                }

                &.active {
                    & > .menuExpand {
                        i {
                            &:before {
                                transform: rotate(0);
                            }
                        }
                    }
                }

                .menuExpand {
                    line-height: 50px;

                    position: absolute;
                    top: -5px;
                    right: auto;
                    left: 95%;

                    width: 30px;
                    height: 50px;

                    cursor: pointer;
                    text-align: center;

                    i {
                        position: relative;

                        display: block;

                        width: 10px;
                        margin-top: 25px;

                        transition: all 250ms ease-out;

                        border-bottom: 1px solid;
                        &:before {
                            position: absolute;
                            top: 0;

                            display: block;

                            width: 100%;

                            content: "";
                            transform: rotate(90deg);

                            border-bottom: 1px solid;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeInDown {
    0% {
        transform: translate3d(0, -100px, 0);

        opacity: 0;
    }
    to {
        transform: none;

        opacity: 1;
    }
}
